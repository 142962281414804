import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"

const emojiCss = css`
  display: inline !important;
  border: none !important;
  box-shadow: none !important;
  height: 1em !important;
  width: 1em !important;
  margin: 0 0.07em !important;
  vertical-align: -0.1em !important;
  background: none !important;
  padding: 0 !important;
`

const Wrapper = styled("div")`
  color: #eb3d95;
  text-align: center;
  font-size: 22px;
  font-weight: 500;
`
const SakuraText = ({ text, size }) => {
  const baseCss = css`
    color: #eb3d95;
    text-align: center;
    font-size: 22px;
    font-weight: 500;
  `

  let finalCss = baseCss
  if (size) {
    finalCss = css`
      ${baseCss};
      font-size: ${size}px;
    `
  }

  return (
    <div css={finalCss}>
      <img
        css={emojiCss}
        draggable="false"
        alt="🌸"
        src="https://s.w.org/images/core/emoji/13.0.0/svg/1f338.svg"
      />
      {text}
      <img
        css={emojiCss}
        draggable="false"
        alt="🌸"
        src="https://s.w.org/images/core/emoji/13.0.0/svg/1f338.svg"
      />
    </div>
  )
}

export default SakuraText
